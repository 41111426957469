@import '~antd/dist/antd.css';
body {
  background: rgb( 237, 240, 243 );
}
header span {
  color: white;
  font-weight: 600;
  font-size: 16pt;
  text-align: left;
}
header {
  text-align: left;
}
.ant-btn-primary {
  border-color: rgb( 97, 182, 225 );
  background: rgb( 97, 182, 225 );
  color: rgb( 255, 255, 255, 0.80);
}
.ant-layout-footer {
  background: rgb( 237, 240, 243 );
}

.bm-stepper {
  background-color: rgb( 217, 57, 52 );
  font-weight: 600;
}
.bm-stepper .bm-stepper-description {
  color: rgb( 255, 255, 255); 
  background-color: rgb( 0, 0, 0, 0.15 ) ; 
  font-weight: 600;
  font-size: 12pt;
}
.bm-stepper .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgb( 255, 255, 255, 0.80);
}
/* Disabled steps */
.bm-stepper .ant-steps-item.ant-steps-item-disabled .ant-steps-item-icon {
  background-color: rgb( 115, 115, 115, 0.40 );
}
.bm-stepper .ant-steps-item.ant-steps-item-disabled > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgb( 255, 255, 255, 0.40);
}


.tile {
  border-radius: 15px;
  background-color: #ddd;
  color: rgb( 0, 0, 0, 0.80);
}
.tile.selectable {
  cursor: pointer;
}
.tile.selected {
  background-color: rgb( 97, 182, 225 );
  color: rgb( 255, 255, 255, 0.80);
}

.clicker { 
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.clicker.selectable:hover { 
  background-color: rgb( 97, 182, 225, 0.20 )
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


